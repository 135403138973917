import React from "react";
import { useEffect, useState } from "react";
import { FieldConfigInterface } from '../interfaces/serviceRunsInterfaces'
import { useServiceRunsContext } from "../context/ServiceRuns-context";


export const useCreateColumnsObject = (data_fields: FieldConfigInterface[]) => {
    const { setColumns } = useServiceRunsContext();

    useEffect(() => {
        if (data_fields.length > 0) {
            setColumns(data_fields.filter(dl => dl.inTable === true).map(dl => ({
                field: dl.id,
                headerName: dl.label,
                sortable: true,
                minWidth: 100,
                disableExport: dl.disableExport,
                flex: 1,
                renderCell: (params: { value: string | number | string[] | number[] }) =>
                    Array.isArray(params.value) ?
                        <div>
                            {params.value.map((item, index) => (
                                <React.Fragment key={index}>
                                    <span>{item}</span>
                                    <br />
                                </React.Fragment>
                            ))}
                        </div> :
                        params.value
            })))
        }
    }, [data_fields])
}

export const useGetColumnInitialState = (data_fields: FieldConfigInterface[]) => {

    const [initialState, setInitialState] = useState<{columns: {columnVisibilityModel:{[key: string]: boolean}}}>({
        columns: {
          columnVisibilityModel: {},
        },
      });

    useEffect(() => {
        if (data_fields.length > 0) {
            setInitialState(prev => {
                const current = {...prev}
                data_fields.filter(dl => dl.inTable === true).forEach(f => current.columns.columnVisibilityModel[f.id] = f.shown)
                return current
            })
        }

    }, [data_fields])

    return initialState;
}