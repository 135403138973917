import { useEffect, useState } from "react";
import { useCheckQueryReady } from '../../../app/modules/ServiceRuns/hooks/RoleTableHooks'
import { FC } from 'react';
import { useServiceRunsContext } from '../../../app/modules/ServiceRuns/context/ServiceRuns-context'
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import { useAsyncConnectDBQuery } from '../../../app/modules/ServiceRuns/redux/cloudAppAPI';
import { useWindowFocus } from '../../../app/hooks/useWindowFocus'
import { useGlobalContext } from '../../../app/components/GlobalContext/GlobalContext'
import { useLocation } from 'react-router-dom'
import axios from "axios";
import { useAuthRedux } from "../../../app/modules/auth/redux/useAuthRedux";
const backend_endpoint = process.env.REACT_APP_BACKEND_ENDPOINT
export interface DownloadbuttonInterface {
  form: {
    touched: {},
    initialValues: { jsonID: { [key: string]: string } },
    errors: { jsonID: { [key: string]: string[] } },
    setFieldTouched: (a: string, b: boolean) => {},
    setFieldValue: (a: string, b: {}) => {},
    values: { jsonID: { [key: string]: (string | null) | Array<{ [key: string]: string | null }> | null }, serviceID: string }
  },
  label: string,
  required: boolean,
  isLoading: boolean,
  disabled: boolean,
  queryparams: string
}

export const Downloadbutton: FC<DownloadbuttonInterface> = ({
  label,
  isLoading,
  disabled,
  queryparams,
  form
}) => {
  const windowFocused = useWindowFocus();
  const location = useLocation()
  const { setLoaderMessage } = useGlobalContext();
  const [downloadClicked, setDownloadClicked] = useState(false)
  const [dataFlag, setDataFlag] = useState(false);
  const { setErrorMessage, setQueryTrigger, queryTrigger } = useServiceRunsContext();
  const { notPopulatedFieldsArrayState, queryValuesObject } = useCheckQueryReady(form, queryparams);
  const { accessToken, graphToken } = useAuthRedux();
  const { data = false, isFetching, error } = useAsyncConnectDBQuery({ queryParams: queryValuesObject, service: form.values.serviceID, fieldName: label, type: "action_buttons_config" },
    {
      skip: (!queryTrigger || notPopulatedFieldsArrayState.length !== 0),
      pollingInterval: windowFocused ? 5000 : 0,
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true
    })
  
    useEffect(() => {
      if(isFetching) {
        setDataFlag(true)
      }
    }, [isFetching])
  

  useEffect(() => {
    if (downloadClicked) {
      if (notPopulatedFieldsArrayState.length > 0) {
        setErrorMessage(`Fields ${notPopulatedFieldsArrayState.join(",")} are not properly populated.`)
      } else {
        setErrorMessage(``)
      }

    }
  }, [notPopulatedFieldsArrayState, downloadClicked])

  useEffect(() => {
    if (queryTrigger && notPopulatedFieldsArrayState.length === 0) {
      setLoaderMessage("Downloading")
    } else {
      setLoaderMessage("")
      setQueryTrigger(false)
    }
  }, [queryTrigger, notPopulatedFieldsArrayState])

  useEffect(() => {
    setDownloadClicked(false)
    setErrorMessage(``)
  }, [location])


  // useEffect(() => {

  //   if ("tabledata" in data) {
  //     const csv = Papa.unparse(data.tabledata, {quoteChar: '"', quotes: true, delimiter: ","});
  //     const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
  //     // Using saveAs function from FileSaver to save the data as CSV file
  //     saveAs(blob, `${data.fileName}`);
  //     setQueryTrigger(false)
  //   }
  //   if (error) {
  //     setQueryTrigger(false)
  //   }
  // }, [data, error])

  
  useEffect(() => {
    const getData = async () => {
      const response = await axios.post(`${backend_endpoint}filedownloadfromcache/`, {
        queryParams: queryValuesObject,
        service: form.values.serviceID,
        fieldName: label,
        type: "action_buttons_config"
      },
        {
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Authorizationgraph: graphToken!
          }
        });
      // Extracting filename from the Content-Disposition header
      const contentDisposition = response.headers['content-disposition'];
      let fileName = 'lcl_hier.csv';
      if (contentDisposition) {
          const filenameRegex = /filename="([^"]+)"/;
          const matches = filenameRegex.exec(contentDisposition);
          if (matches != null && matches[1]) { 
              fileName = matches[1];
          }
      }
      saveAs(response.data, `${fileName}`);
      setQueryTrigger(false)
    }
    console.log(data,queryTrigger)
    if (data && queryTrigger && dataFlag && !isFetching) {
      console.log(data,queryTrigger)
      // const csv = Papa.unparse(data.tabledata, {quoteChar: '"', quotes: true, delimiter: ","});
      // const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
      // Using saveAs function from FileSaver to save the data as CSV file
      setQueryTrigger(false)
      setDataFlag(false)
      getData()
    }
    if (error) {
      setQueryTrigger(false)
    }
  }, [data, error, queryTrigger])


  return (
    <>
      <button
        type='button'
        className='btn btn-color-primary btn-primary text-white btn-sm me-5'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        data-kt-menu-flip='top-end'
        onClick={() => {
          setQueryTrigger(true);
          setDownloadClicked(true);
        }}
        disabled={queryTrigger || isFetching || isLoading}
      >
        <div className="d-flex align-items-center">
          <span>{label}</span>
          {queryTrigger && (
            <span className="spinner-border spinner-border-sm text-light ms-2"></span>
          )}
        </div>
      </button>
    </>
  );
}