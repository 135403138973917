import { Popover, OverlayTrigger } from "react-bootstrap";
import { FC } from 'react'


const loadSectionHelp = (fieldData: getFieldHelpInterface) => (
  <Popover id="popover-basic" className="popover-mobile">
    <h3 className="popover-header">Help:</h3>
    <div className="popover-body  word-wrap">

      <div key={Math.random()}>
        {/* <div className="row fw-bold font-size-sm"> */}
        {/* <div className="col-12"> */}
        <div dangerouslySetInnerHTML={{
          __html: fieldData.info
        }} />
        {fieldData.url && fieldData.url !== '#' ?
          <span className='mt-3 label label-inline fw-bold label-light-primary'>
            <a href={fieldData.url} target="blank">Read more here</a>
          </span> : null}
        {/* </div> */}
        {/* </div> */}
      </div>
    </div>
  </Popover>
)

type Props = {
  help?: getFieldHelpInterface
  className?: string
}

export interface getFieldHelpInterface {info: string, url: string }

export const PopoverHelper: FC<Props> = (props) => {
  

  return (
    props.help ?
      <OverlayTrigger trigger="click" rootClose placement="top" overlay={loadSectionHelp(props.help)}>
        {/* <div className="ms-1 input-group-append" style={{borderLeft: '3px solid #6993FF'}}> */}
        <div className="input-group-append">
          {/* <span className="input-group-text bg-white btn btn-icon btn-light btn-hover-primary"> */}
          <span className={!props.className ?
            "input-group-text btn btn-icon btn-secondary btn-hover-primary no-left-radius"
            : "input-group-text btn btn-icon btn-secondary btn-hover-primary no-left-radius " + props.className}>
            {/* <i className="fas fa-question-circle text-primary me-1"></i> */}
            <i className="fas fa-question-circle text-primary"></i>
          </span>
        </div>
      </OverlayTrigger> : null
  )
}