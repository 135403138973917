import axiosRetry from 'axios-retry';
import axios, { AxiosRequestConfig } from "axios";

export const setupAxios = (graphToken: string) => {
    const instance = axios.create();

    axiosRetry(instance, {
        retries: 3,
        retryCondition: (error) => {
            return error.message === 'Network Error' || (error.response!.status > 400 && !error.response!.data.errorMessage)
        },
        retryDelay: () => {
            return 3 * 1000;
        }
    });

    instance.interceptors.request.use(
        (config: AxiosRequestConfig) => {
            config.headers!.Authorization = graphToken;
            return config;
        },
        err => Promise.reject(err)
    );

    return instance;
}
