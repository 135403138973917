import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from 'react';
import { TableComponent } from "../../../app/components/table/NewTable";
import { useMemo } from "react";
import { FC } from 'react'
import { useLazyConnectDBQuery } from "../../../app/modules/ServiceRuns/redux/cloudAppAPI";
import { useCreateColumnsObject, useGetColumnInitialState } from '../../../app/modules/ServiceRuns/hooks/TableHooks'
import { useCheckQueryReady } from '../../../app/modules/ServiceRuns/hooks/RoleTableHooks'
import { useServiceRunsContext } from "../../../app/modules/ServiceRuns/context/ServiceRuns-context";
import { useGlobalContext } from "../../../app/components/GlobalContext/GlobalContext";
import { useSetTableNewFieldsDefaults } from "../../../app/modules/ServiceRuns/hooks/DynamicServiceHooks";
import { FieldConfigInterface } from "../../../app/modules/ServiceRuns/interfaces/serviceRunsInterfaces";
import { GridRowSelectionModel, GridSortDirection } from "@mui/x-data-grid-pro";

export interface TableFieldInterface {
  field: {
    name: string,
    value: string | number | undefined | number
  },
  form: {
    touched: {},
    errors: { jsonID: { [key: string]: string[] } },
    setFieldTouched: (a: string, b: boolean) => {},
    setFieldValue: (a: string, b: string | number | Array<string | number> | {[key: string]: string}[]) => {},
    values: { serviceID: string, jsonID: { [key: string]: string } },
    initialValues: { jsonID: { [key: string]: string } }
  },
  data_fields: FieldConfigInterface[],
  queryparams: string,
  service_descriptive_name: string,
  newRecordLabel: string,
  hideToolBar: boolean,
  disableTableEdit: boolean,
  checkboxSelection: boolean
}

export const TableField: FC<TableFieldInterface> = ({
  field,
  form,
  data_fields,
  queryparams,
  service_descriptive_name,
  newRecordLabel,
  hideToolBar,
  disableTableEdit,
  checkboxSelection
}) => {

  const navigate = useNavigate();
  const [trigger, { data, isLoading, isFetching }] = useLazyConnectDBQuery();
  const { paginationModel,
    setPaginationModel,
    setSortModelData,
    sortModelData,
    setEditTable,
    editTable,
    setNewRow,
    filterModel,
    setFilterModel,
    columns
  } = useServiceRunsContext();
  const [records, setRecords] = useState<{ [key: string]: string }[]>([]);
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const { bu } = useGlobalContext();
  const defaultNewValues = useSetTableNewFieldsDefaults(data_fields);
  useCreateColumnsObject(data_fields);
  const initialState = useGetColumnInitialState(data_fields);
  const { queryValuesObject, queryReady } = useCheckQueryReady(form, queryparams);

  useEffect(() => {
    if(checkboxSelection) form.setFieldValue('jsonID.rowSelection', records.filter(r => rowSelectionModel.includes(r.id)))
  },[rowSelectionModel])
  
  // useEffect(() => {
  //   console.log(columns,"columns")
  //   setSortModelData([{ field: data_fields[0].id, sort: 'desc' as GridSortDirection }])
  // }, [form.values.serviceID, columns])
 
 
  //   setSortModelData([{ field: data_fields[0].id, sort: 'desc' as GridSortDirection }])
  // }, [data_fields[0].id])

  useEffect(() => {
    // set !editTable in order to escape running when click on row. It cause problems in useCheckQueryReady
    if (queryReady && !editTable && service_descriptive_name === form.values.serviceID && records.length === 0) {
      trigger({ queryParams: queryValuesObject, service: form.values.serviceID, fieldName: field.name.replace('jsonID.', ''), type: "field_config" })
    }

  }, [JSON.stringify(queryValuesObject), records])
  // we trigger the query only on queryValuesObject changes

  useEffect(() => {

    if (data && "tabledata" in data!) {
      setRecords(data!.tabledata)
    }
  }, [data])

  useEffect(() => {
    setRecords([])
  }, [bu, form.values.serviceID, JSON.stringify(queryValuesObject)])

  const handleRowClick = useCallback((params: { row: { can_click: boolean; service_descriptive_name: string; } }) => {
    if (params.row.can_click) {
      setEditTable(true)
      navigate(`/services/createservicerun/${form.values.serviceID}`, {
        state: { service_descriptive_name: form.values.serviceID, json_payload: { ...form.values.jsonID, ...params.row } }
      });
    }
  }, [form.values.serviceID])

  const newRecord = () => {
    setNewRow(true)
    navigate(`/services/createservicerun/${form.values.serviceID}`, {
      state: { service_descriptive_name: form.values.serviceID, json_payload: { ...form.values.jsonID, ...defaultNewValues } }
    });
  }

  return (
    <>
      <TableComponent
        columns={columns}
        filteredData={useMemo(() => (records), [records])}
        loading={isFetching || isLoading}
        handleRowClick={handleRowClick}
        newRecord={newRecord}
        paginationModel={useMemo(() => paginationModel, [paginationModel])}
        onPaginationModelChange={setPaginationModel}
        sortModelData={useMemo(() => sortModelData, [sortModelData])}
        onSortModelDataChange={setSortModelData}
        newRecordLabel={newRecordLabel}
        filterModel={filterModel}
        setFilterModel={setFilterModel}
        initialState={initialState}
        hideToolBar={hideToolBar}
        disableTableEdit={disableTableEdit}
        checkboxSelection={checkboxSelection}
        setRowSelectionModel={setRowSelectionModel}
        rowSelectionModel={rowSelectionModel}
      />
    </>
  )
}
