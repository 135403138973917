import { createRoot } from 'react-dom/client'
// Apps
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.react.scss'
import "./_metronic/assets/plugins/flaticon/flaticon.css";
import "./_metronic/assets/plugins/flaticon2/flaticon.css";
import { AppRoutes } from './app/routing/AppRoutes'
import { MsalProvider } from "@azure/msal-react";
import { Provider } from 'react-redux'
import store from './app/redux/store'
import "./_metronic/assets/plugins/keenthemes-icons/font/ki.css";
import { MSALSetup } from "./app/modules/auth/core/authFunctions"
import "react-datepicker/dist/react-datepicker.css";
import ErrorBoundary from './app/modules/errors/ErrorBoundary'
import { GlobalContextProvider } from './app/components/GlobalContext/GlobalContext'
export const msalInstance = MSALSetup()

const container = document.getElementById('root')
if (container) {
  createRoot(container).render(
    <GlobalContextProvider>
      <MsalProvider instance={msalInstance}>
        <Provider store={store}>
          <ErrorBoundary>
            <AppRoutes />
          </ErrorBoundary>
        </Provider>
      </MsalProvider>
    </GlobalContextProvider>
  )
}
