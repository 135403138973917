import { PopoverHelper, getFieldHelpInterface } from "./PopoverHelper";
import { getIn } from "formik";
import { FC, useEffect, useState } from 'react'
import { useCheckQueryReady } from "../../../app/modules/ServiceRuns/hooks/RoleTableHooks";
import { useLazyConnectDBQuery } from "../../../app/modules/ServiceRuns/redux/cloudAppAPI";

const getFieldCSSClasses = (touched: {}, errors: {}) => {
    const classes = ["form-control select-drop-down",];
    if (touched && errors) {
        classes.push("is-invalid");
    }

    if (touched && !errors) {
        classes.push("is-valid");
    }

    return classes.join(" ");
};

const requiredFieldStyle = (req: boolean) => {
    return req ? <i className="fa fa-star-of-life icon-xs text-danger ms-1"></i> : null
}

export interface QuerySelectInterface {
    field: {
        name: string,
        value: string | number | undefined | number
    },
    form: {
        initialValues: { jsonID: { [key: string]: string } },
        touched: {},
        errors: { jsonID: { [key: string]: string[] } },
        values: { jsonID: { [key: string]: (string | null) | Array<{ [key: string]: string | null }> | null | string[] }, serviceID: string }
        setFieldTouched: (a: string, b: boolean) => {},
        setFieldValue: (a: string, b: string | number | Array<string | number>) => {}
    },
    label: string,
    required: boolean,
    disabled: boolean,
    help?: getFieldHelpInterface,
    hidden: boolean,
    queryparams: string,
    service_descriptive_name: string,
    optionsMap: { name: string, value: string },
    filterBy: string[]
}


export const QuerySelect: FC<QuerySelectInterface> = ({
    field,
    form,
    label,
    help,
    required = true,
    disabled,
    hidden = false,
    queryparams,
    service_descriptive_name,
    optionsMap,
    filterBy
}) => {
    const { touched, errors, setFieldTouched, setFieldValue, values } = form;
    const errorMessage = getIn(errors, field.name);
    const touch = getIn(touched, field.name);
    const { queryValuesObject, queryReady } = useCheckQueryReady(form, queryparams);
    const [trigger, { data, isLoading, isFetching }] = useLazyConnectDBQuery();
    const [options, setOptions] = useState<{ [key: string]: string }[]>([]);

    useEffect(() => {
        if (queryReady && service_descriptive_name === values.serviceID) {
            trigger({ queryParams: queryValuesObject, service: values.serviceID, fieldName: field.name.replace('jsonID.', ''), type: "field_config" })
        }

    }, [JSON.stringify(queryValuesObject)])
    
    useEffect(() => {
        if(data && "tabledata" in data ) {
            if(filterBy) {
                if(filterBy.every(f=> values.jsonID[f] !== "default" && values.jsonID[f] !== "")) {
                    setOptions(data.tabledata.filter(entry => filterBy.every(field => entry[field] ===  values.jsonID[field])))
                } else {
                    setOptions([])
                }

            } else {
                setOptions(data.tabledata)
            }
        }
        
    }, [data, values])

    return (
        <>
            {label && <label hidden={hidden} className="fw-bolder font-size-h4 label-custom mb-2">{label}{requiredFieldStyle(required)}</label>}
            <div className="input-group">
                {
                    <select
                        hidden={hidden}
                        style={{ color: field.value === 'default' ? "#BFBBD3" : "inherit" }}
                        className={getFieldCSSClasses(touch, errorMessage)}
                        {...field}
                        value={field.value}
                        disabled={disabled || isLoading || isFetching}
                        onChange={(e) => {
                            setFieldTouched(field.name, false);
                            setFieldValue(field.name, e.target.value);
                        }}
                    >
                        {
                            data && "tabledata" in data ? [(<option style={{ color: "#BFBBD3" }} value={"default"} key={"default"} disabled>{isLoading || isFetching ? "Loading..." : "Select Source System"}</option>),...options.map(opt => {return (<option style={{ color: "#464E5F" }} value={opt[optionsMap.value]} key={opt[optionsMap.value]}>{opt[optionsMap.name]}</option>)})] :
                             (<option style={{ color: "#BFBBD3" }} value={"default"} key={"default"} disabled>Loading...</option>)
                        }
                    </select>}
                <PopoverHelper help={help} />
            </div>
            {
                touch && errorMessage && <div className="invalid-feedback" style={{ display: 'block' }}>{errorMessage}</div>
            }
        </>
    );
}
