import { AccountInfo } from "@azure/msal-browser";
export const msalConfig = {
  auth: {
    clientId: "ff3d6650-4ae2-4c07-b3c7-fdf70a405775",
    authority: "https://login.microsoftonline.com/548d26ab-8caa-49e1-97c2-a1b1a06cc39c",
  },
  // cache: {
  //   cacheLocation: "sessionStorage", // This configures where your cache will be stored
  //   storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  // }
};
const GRAPH_SCOPES = {
  OPENID: "openid",
  PROFILE: "profile",
  USER_READ: "User.Read",
  APP: "api://ff3d6650-4ae2-4c07-b3c7-fdf70a405775/nsrudpapi.all.all",
};

const GRAPH_SCOPES_MGT = {
  USER_READ: "user.read",
  PEOPLE_READ: "people.read",
  USER_READBASIC_ALL: "user.readbasic.all",
};

export const GRAPH_REQUESTS = {
  LOGIN: {
    scopes: [
      GRAPH_SCOPES.APP,
      // GRAPH_SCOPES.OPENID,
      // GRAPH_SCOPES.PROFILE,
      // GRAPH_SCOPES.USER_READ,
    ]
  },
  // GROUP: {
  //     scopes: [GRAPH_SCOPES.USER_READ]
  // },
  MGT: {
    scopes: [
      GRAPH_SCOPES_MGT.USER_READ,
      GRAPH_SCOPES_MGT.PEOPLE_READ,
      GRAPH_SCOPES_MGT.USER_READBASIC_ALL
    ]
  }
};

export const loginScope = {
  scopes: GRAPH_REQUESTS.LOGIN.scopes,
  forceRefresh: true // Set this to "true" to skip a cached token and go to the server to get a new token
};

export const loginScopeMGT = {
  scopes: GRAPH_REQUESTS.MGT.scopes,
  forceRefresh: true // Set this to "true" to skip a cached token and go to the server to get a new token
  // scopes: [apiConfig.resourceScope]
}

export interface scopeInterface {
  scopes: string[],
  forceRefresh: boolean,
  account?: AccountInfo
}