import DatePicker from "react-datepicker";
import { PopoverHelper, getFieldHelpInterface } from "./PopoverHelper";
import { getIn } from "formik";
import { FC, useState, useEffect } from 'react'

const getFieldCSSClasses = (touched: {}, errors: {}) => {
    const classes = ["form-control p-0"];
    if (touched && errors) {
        classes.push("is-invalid");
    }

    if (touched && !errors) {
        classes.push("is-valid");
    }

    return classes.join(" ");
};

const requiredFieldStyle = (req: boolean) => {
    return req ? <i className="fa fa-star-of-life icon-xs text-danger ms-1"></i> : null
}

export interface DatePickerRangeFieldInterface {
    field: {
        name: string,
        value: [Date | null, Date | null]
    },
    form: {
        touched: {},
        errors: {},
        setFieldTouched: (a: string, b: boolean) => {},
        setFieldValue: (a: string, b: [Date | null, Date | null]) => {},
        handleSubmit: () => {},
        values: {}
    },
    label: string,
    required: boolean,
    isLoading: boolean,
    disabled: boolean,
    help?: getFieldHelpInterface,
    placeholder: string,
    editmode: boolean,
    novalidation: boolean,
    submitOnChange: boolean
}

export const DatePickerRangeField: FC<DatePickerRangeFieldInterface> = ({
    field,
    form: { touched, errors, setFieldTouched, setFieldValue, values, handleSubmit },
    label,
    required = true,
    isLoading,
    disabled,
    placeholder,
    help,
    novalidation = false,
    editmode,
    submitOnChange = false
}) => {
    const themeMode = localStorage.getItem("kt_theme_mode_value");
    const errorMessage = getIn(errors, field.name);
    const touch = getIn(touched, field.name);
    const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
    const [startDate, endDate] = dateRange;
    const getDate = (date: Date) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const datePortion = `${year}-${month}-${day}`;
        return datePortion;
    }
    //trigger yup additionally
    useEffect(() => {
        if (touch) {
            setFieldTouched(field.name, true)
        }
    }, [dateRange, touch])

    useEffect(() => {
        if (JSON.stringify(field.value) !== JSON.stringify([null, null]) && JSON.stringify(field.value) !== JSON.stringify(dateRange)) {
            setDateRange([(new Date(field.value[0]!)), new Date(field.value[1]!)])
        }

    }, [])

    useEffect(() => {
        if (JSON.stringify(field.value) === JSON.stringify([null, null])) setDateRange([null, null])
    }, [field.value])


    return (
        <>
            {label && <label className="fw-bolder font-size-h4 label-custom mb-2">{label}{requiredFieldStyle(required)}</label>}

            {
                <div className="input-group ">
                    <DatePicker
                        wrapperClassName={`${novalidation ? "" : getFieldCSSClasses(touch, errorMessage)} ${themeMode === "light" ? 'datepicker-wrapper' : 'datepicker-wrapper-dark'}`}
                        className={themeMode === "light" ? "datepicker-input" : "datepicker-input-dark"}
                        placeholderText={placeholder}
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        disabled={disabled}
                        onChange={(update) => {
                            setDateRange(update);
                            // if date range is today we change it to current hour
                            if(update[1]){
                                if(getDate(update[1])===getDate(new Date())) {
                                    update[1] = new Date()
                                }
                            }
                            // change the date range to the current time zone 
                            if (update[0] && update[1]) {
                                update[0].setTime(update[0].getTime() - update[0].getTimezoneOffset() * 60000);
                                update[1].setTime(update[1].getTime() - update[1].getTimezoneOffset() * 60000);
                            }

                            setFieldValue(field.name, update)
                            setFieldTouched(field.name, true)
                            if(submitOnChange && update[0] && update[1]) handleSubmit()
                        }}
                        includeDateIntervals={[{ start: new Date(2000, 1, 1), end: new Date() }]}
                        showYearDropdown
                    />
                    <PopoverHelper help={help} />
                </div>
            }
            {
                touch && errorMessage && <div className="invalid-feedback" style={{ display: 'block' }}>{errorMessage}</div>
            }

        </>
    );
}
