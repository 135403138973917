import { useEffect, useState } from "react";
import { useAuthRedux } from '../modules/auth/redux/useAuthRedux'
import { toast } from 'react-toastify';
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { SerializedError } from "@reduxjs/toolkit";
import { appInsights } from '../../app/app_insight/app_insight_config'
import { SeverityLevel } from "@microsoft/applicationinsights-web";


export const useSetErrorMessage = (errorArray: Array<{ error: FetchBaseQueryError | SerializedError | undefined, function: string }>) => {
  const [errorState, setErrorState] = useState(false)
  const { showExpiringModal, userName } = useAuthRedux();
  const skipQuery = showExpiringModal ? true : false;

  useEffect(() => {
    if (errorArray.some(element => element.error) && !skipQuery) {
      appInsights.trackException({ error: JSON.stringify(errorArray) as unknown as Error, severityLevel: SeverityLevel.Error, properties: { 'source': 'nsr_portal', 'user': userName } });
      setErrorState(true)
    }
  }, [...errorArray, skipQuery]);
  useEffect(() => {
    if (errorState) {
      toast.error("Communication error. Please try again later by refreshing browser window!!!");
    }
  }, [errorState]);

};