import {
  DataGridPro,
  gridClasses,
  GridColDef,
  GridRowsProp,
  GridSortModel,
  GridPaginationModel,
  GridCallbackDetails,
  GridEventListener,
  GridToolbarExport,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridRowSelectionModel,
  GridFilterModel
} from '@mui/x-data-grid-pro';
import { CustomFooter } from './Footer';
import { CustomNoRowsOverlay } from './CustomNoRowsOverlay';
import { FC, memo } from 'react'
import AddIcon from '@mui/icons-material/Add';
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';

interface TableComponentInterface {
  columns: GridColDef[],
  filteredData: GridRowsProp,
  loading: boolean,
  handleRowClick?: GridEventListener<'rowClick'>,
  sortModelData: GridSortModel,
  paginationModel: GridPaginationModel,
  onPaginationModelChange: (model: GridPaginationModel, details: GridCallbackDetails) => void,
  onSortModelDataChange: (model: GridSortModel, details: GridCallbackDetails) => void,
  newRecord?: () => void,
  newRecordLabel?: string,
  filterModel: GridFilterModel,
  setFilterModel: React.Dispatch<React.SetStateAction<GridFilterModel>>,
  initialState?: GridInitialStateCommunity,
  hideToolBar?: boolean,
  disableTableEdit?: boolean,
  checkboxSelection?: boolean,
  setRowSelectionModel?: React.Dispatch<React.SetStateAction<GridRowSelectionModel>>,
  rowSelectionModel?:GridRowSelectionModel
}

export const TableComponent: FC<TableComponentInterface> = memo(({
  columns,
  filteredData,
  loading,
  handleRowClick,
  sortModelData,
  paginationModel,
  onPaginationModelChange,
  onSortModelDataChange,
  newRecord,
  newRecordLabel,
  filterModel,
  setFilterModel,
  initialState= undefined,
  hideToolBar = false,
  disableTableEdit = false,
  checkboxSelection = false,
  setRowSelectionModel,
  rowSelectionModel
}) => {
  const themeMode = localStorage.getItem("kt_theme_mode_value");

  // const [filterModel, setFilterModel] = useState<GridFilterModel>({
  //   items: [], // Initialize with an empty array
  // });

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
        {newRecord && <div
          className="d-flex align-items-center table-button-hover"
          style={{
            color: '#b5b5c3',
            fontSize: '0.8125rem',
            cursor: 'pointer',
            paddingRight: '3px',
            paddingTop: '2px',
            paddingBottom: '2px'
          }}
          onClick={newRecord}
        >
          <AddIcon />
          <div className='ms-1'>{`NEW ${newRecordLabel}`}</div>
        </div>
        }
      </GridToolbarContainer>
    );
  }

  return <>
    <style>{`.MuiDataGrid-root { border: none; }`}</style>
    <DataGridPro
      pagination
      getRowHeight={() => 'auto'}
      sx={{
        // '& .MuiDataGrid-cell:hover': {
        //     color: '#3783E7',
        // },
        '& .MuiDataGrid-cell': {
          paddingY: '7px',
          overflowWrap: 'anywhere',
          color: `${themeMode === 'dark' ? 'white' : '#000000de'}`
        },
        '& .MuiBox-root': {
          color: `${themeMode === 'dark' ? 'white' : '#000000de'}`
        },
        '& .MuiCircularProgress-colorPrimary': {
          color: '#3783E7',
        },
        '& .MuiDataGrid-columnHeaderTitle': {
          fontWeight: 600,
          color: "#b5b5c3",
          textTransform: 'uppercase',
          letterSpacing: '.1rem',
          overflow: 'visible !important',
          fontSize: '.9rem',
          whiteSpace: "normal",
          lineHeight: "normal"
        },
        '& .MuiButtonBase-root': {
          color: "#b5b5c3 !important",
        },
        '& .MuiDataGrid-row': {
          fontSize: '13px'
        },
        '& .MuiToolbar-root-MuiTablePagination-toolbar': {
          backgroundColor: '#f5f5f5',
          borderBottom: '1px solid #ccc',
          padding: '10px',
        },
        '& .MuiTablePagination-selectLabel': {
          marginY: '0px'
        },
        '& .MuiTablePagination-input': {
          color: `${themeMode === 'dark' ? 'white' : '#000000de'}`
        },
        '& .MuiTablePagination-selectIcon': {
          color: `${themeMode === 'dark' ? 'white' : '#000000de'}`
        },
        '& .MuiTablePagination-displayedRows': {
          marginY: '0px',
          color: `${themeMode === 'dark' ? 'white' : '#000000de'}`
        },
        '& .MuiDataGrid-footerContainer': {
          paddingTop: '20px'
        },
        '& .MuiDataGrid-columnHeader:focus': {
          outline: 'none'
        },
        '.MuiTablePagination-root .MuiTablePagination-selectLabel': {
          display: 'none'
        },
        '& .MuiButtonBase-root-MuiMenuItem-root-MuiTablePagination-menuItem.Mui-selected': {
          backgroundColor: 'red',
        },
        [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
          outline: "none"
        },
        [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
          outline: "none"
        },
        "& .MuiDataGrid-columnHeader": {
          // Forced to use important since overriding inline styles
          height: "unset !important"
        },
        "& .MuiDataGrid-columnHeaders": {
          // Forced to use important since overriding inline styles
          maxHeight: "168px !important"
        },
        "& .MuiDataGrid-overlayWrapper": {
          top: "0"
        },
        "& .MuiDataGrid-toolbarContainer": {
          marginBottom: "6px"
        }
      }}
      slots={{
        toolbar: hideToolBar ? null : CustomToolbar,
        footer: CustomFooter,
        noRowsOverlay: () => <CustomNoRowsOverlay loading={loading} />
      }}
      columns={columns}
      rows={filteredData}
      // loading={loading}
      pageSizeOptions={[3, 5, 10, 30, 50, 100]}
      onRowClick={disableTableEdit ? undefined : handleRowClick}
      // auto-height for the table content
      getRowClassName={(params) => params.row.can_click && !disableTableEdit ? "can-hover-open" : "cannot-hover-open"}
      autoHeight
      // disableColumnMenu
      // disableColumnFilter
      disableDensitySelector
      checkboxSelection = {checkboxSelection}
      disableRowSelectionOnClick
      paginationModel={paginationModel}
      onPaginationModelChange={onPaginationModelChange}
      sortModel={sortModelData}
      onSortModelChange={onSortModelDataChange}
      initialState={initialState}
      filterModel={filterModel}
      onFilterModelChange={setFilterModel}
      onRowSelectionModelChange={setRowSelectionModel ? (newRowSelectionModel) => {
        setRowSelectionModel(newRowSelectionModel);
      } : undefined}
      rowSelectionModel={rowSelectionModel ? rowSelectionModel : undefined}
    />
  </>
})






