import { useEffect, useState } from 'react'
import { useIsAuthenticated } from "@azure/msal-react";
import { useAppDispatch } from '../../../redux/store'
import { useAuthRedux } from '../redux/useAuthRedux'
import { checkTokenExpiration, useGetUserPropertiesQuery } from '../redux/authSlice'
import { toast } from 'react-toastify';
import { useMsal } from "@azure/msal-react";
import { loginScopeMGT } from '../../../modules/auth/core/AuthConfig';

export const useSetupAuthProps = () => {
  const [noActiveMSUserSession, setNoActiveMSUserSession] = useState<boolean>(false)
  const isAuthenticated = useIsAuthenticated();
  const dispatch = useAppDispatch();
  const { accessToken, showExpiringModal } = useAuthRedux();
  const skipExpire = showExpiringModal ? true : false;
  const { error: getUserPropertiesError } = useGetUserPropertiesQuery({}, { skip: !isAuthenticated || skipExpire })
  if (getUserPropertiesError && !skipExpire) toast.error("Communication error. Please try again later!!!");
  const { instance } = useMsal();

  useEffect(() => {
    if (accessToken) {
      dispatch(checkTokenExpiration())
    }
  }, [accessToken])
  
  useEffect(() => {
    const asyndata = async () => {
      if (Object.keys(sessionStorage).filter(el=>!el.includes("AI")).length === 0) {
        // localStorage.removeItem('c3po_auth');
        setNoActiveMSUserSession(true)
      }
    }
    asyndata()

  }, [Object.keys(sessionStorage).length])

  useEffect(() => {
    const asyndata = async () => {
      if (noActiveMSUserSession) {
        await new Promise<void>(done => setTimeout(() => done(), 500));
        const account = instance.getActiveAccount();
        if(!account) {
          await instance.loginRedirect(loginScopeMGT); 
        }
        setNoActiveMSUserSession(false)
      }
    }
    asyndata()

  }, [noActiveMSUserSession])

}