import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit'
import { msalInstance } from "../../../../index";
import { getAccessToken, getUserImage, checkAuthExpiration } from "../core/authFunctions"
import { loginScope, loginScopeMGT } from "../core/AuthConfig"
import { cloudAppAPI } from "../../ServiceRuns/redux/cloudAppAPI"
import { setupAxios } from "../../../axios/setupAxios"
import { RootState } from '../../../redux/store'

interface ServerResponse {
  [x: string]: 
      [{
        onPremisesSamAccountName: string,
        displayName: string,
        mail: string,
      }]
}

export const authAPI = cloudAppAPI.injectEndpoints({
  endpoints: builder => ({
    getUserProperties: builder.query({
      queryFn: async () => {
        const account = msalInstance.getActiveAccount();
        if (!account) {
          throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }
        const graphAccessToken = await getAccessToken(loginScopeMGT);
        const imageURL = await getUserImage(graphAccessToken);
        const accessToken = await getAccessToken(loginScope);
        // const axios = setupAxios(graphAccessToken)
        // const newdata = await axios.get("https://graph.microsoft.com/v1.0/users?$filter=startswith(givenName,'todor') or startswith(surname,'todor') or startswith(userPrincipalName,'todor') or startswith(displayName,'todor')&$select=onPremisesSamAccountName,displayName,userPrincipalName")
        return {
          data: {
            fullName: account.name,
            userName: account.username,
            tokenExpiration: account.idTokenClaims?.exp,
            imageURL: imageURL,
            accessToken,
            graphAccessToken
          }
        }
      },
      keepUnusedDataFor: 60 * 60 * 24 * 365 * 10
    }),
    getGraphUser: builder.query({
      queryFn: async (args,
        { signal, dispatch, getState },
        extraOptions,
        baseQuery) => {
        const stateData = getState() as RootState
        const token = getGraphAccessToken(stateData)
        const axios = setupAxios(token!)
        const newdata = await axios.get<ServerResponse>(`https://graph.microsoft.com/v1.0/users?$filter=startswith(givenName,'${args.args}') or startswith(surname,'${args.args}') or startswith(mail,'${args.args}') or startswith(displayName,'${args.args}')&$select=onPremisesSamAccountName,displayName,mail,id`)
        return {
          data: newdata.data.value
        }
      },
      keepUnusedDataFor: 60 * 60 * 24 * 365 * 10
    }),
    // getUserGroups: builder.query({
    //   queryFn: async (args,
    //     { signal, dispatch, getState },
    //     extraOptions,
    //     baseQuery) => {
    //     const stateData = getState() as RootState
    //     const token = getGraphAccessToken(stateData)
    //     const groupsConfig = getFunctionConfigGroups(stateData);
    //     let allowedObejcts: Array<{func_name: string, allowed: boolean}> = [];
    //     const groupCheck = await fetchMsGraph(`https://graph.microsoft.com/v1.0/me/transitiveMemberOf?$select=displayName&$top=999`,"get",token!, null)
    //     const userGroups = groupCheck.value.map((entry:{displayName: string}) => entry.displayName)
    //     for (const func of groupsConfig) {
    //       allowedObejcts.push({func_name: func.service_descriptive_name, allowed: func.allowed_groups.split(",").some((item: string) => userGroups.includes(item))})
    //     }
    //     return {
    //       data: allowedObejcts
    //     }
    //   },
    //   keepUnusedDataFor: 60 * 60 * 24 * 365 * 10
    // })
  })
})
const userProps = authAPI.endpoints.getUserProperties.select({})
export const getToken = createSelector(userProps, (tokenResult) => tokenResult.data?.accessToken)
export const getUserName = createSelector(userProps, (tokenResult) => tokenResult.data?.userName)
export const getGraphAccessToken = createSelector(userProps, (tokenResult) => tokenResult.data?.graphAccessToken)
export const authProps = authAPI.endpoints.getUserProperties.select({})
export const graphProps = authAPI.endpoints.getGraphUser.select({})
export const { useGetUserPropertiesQuery, useGetGraphUserQuery } = authAPI;

export interface UserState {
  showExpiringModal: Boolean
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: unknown
}

const initialState = {
  showExpiringModal: false,
  status: 'idle',
  error: null
} as UserState

export const checkTokenExpiration = createAsyncThunk('auth/checkTokenExpiration', async (arg, thunkAPI) => {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
  }
  const { firstDelay, secondDelay } = checkAuthExpiration(new Date(account.idTokenClaims?.exp! * 1000).getTime() - new Date().getTime())
  if (firstDelay !== secondDelay) {
    await new Promise<void>(done => setTimeout(() => done(), firstDelay));
    thunkAPI.dispatch(showExpiringModal({}));
    await new Promise<void>(done => setTimeout(() => done(), secondDelay));
  }
  msalInstance.logoutRedirect()
})

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    showExpiringModal: (state, action) => {
      state.showExpiringModal = true;
    },
    hideExpiringModal: (state, action) => {
      state.showExpiringModal = false;
    },
  }
})

export const { showExpiringModal, hideExpiringModal } = authSlice.actions

export default authSlice.reducer