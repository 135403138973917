import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useAuthRedux } from "../redux/useAuthRedux"
import { loginScopeMGT } from '../core/AuthConfig';
import { useMsal } from "@azure/msal-react";
import {useGetUserPropertiesQuery } from '../redux/authSlice'

 
export function ExpiringSessionModal() {
  const [show, setShow] = useState(false);
  const { showExpiringModal, hideExpiringModalAction } = useAuthRedux();
  const { instance } = useMsal();
  const { refetch } = useGetUserPropertiesQuery({})

  useEffect(()=>{
    if(showExpiringModal) setShow(true)
    else setShow(false)
  },[showExpiringModal])

  const handleClose = () => {
    setShow(false);
  }

  const handleLogout = () => {
    hideExpiringModalAction()
    setShow(false)
    instance.logoutRedirect()
  }

  const handleLogin = () => {
    hideExpiringModalAction()
    setShow(false)
    refetch()
    // instance.loginRedirect(loginScopeMGT);
  }

  return (
    <Modal
      backdrop='static'
      onHide={handleClose}
      show={show}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Session Expiration
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center">
        <i className="flaticon2-warning text-warning icon-4x me-5"></i>
        <span className="font-size-h6">Session will expire in the next few minutes! <br /> <div className="ms-21"> Stay Logged In? </div></span>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={handleLogin}
            className="btn btn-success btn-sm"
          >
            Yes, Stay on Page
          </button>
          <> </>
          <button
            type="button"
            onClick={handleLogout}
            className='btn btn-danger btn-sm'
          >
            <span>No, Logout</span>
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
