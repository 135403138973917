/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import { useMsal } from "@azure/msal-react";
import { useAuthRedux } from "../../../../app/modules/auth"

const HeaderUserMenu: FC = () => {
  const { instance } = useMsal();
  const { userName, fullName, imageURL } = useAuthRedux();

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            {imageURL ? <img alt='User profile' src={imageURL} /> :
            <span className="symbol-label font-size-h5 fw-bold">{fullName ? `${fullName[0]}${fullName.split(" ")[1][0]}` : null}</span>}
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {fullName}
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {userName}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <a onClick={() => instance.logoutRedirect()} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
