import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { HomePage } from '../pages/HomePage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import { Menu } from '../pages/Menu'

const PrivateRoutes = () => {
  const ServiceRuns = lazy(() => import('../pages/ServiceRuns'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to homepage after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/homepage' />} />
        {/* Pages */}
        <Route path='homepage' element={<HomePage />} />
        {/* <Route path='menu' element={<Menu />} /> */}
        {/* Lazy Modules */}
        <Route
          path='services/*'
          element={
            <SuspensedView>
              <ServiceRuns />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
