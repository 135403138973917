import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { FC } from 'react'
import { WithChildren } from '../../../_metronic/helpers/react18MigrationHelpers'

const themeMode = localStorage.getItem("kt_theme_mode_value");

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  backgroundColor: `${themeMode === 'dark' ? '#1e1e2d !important' : '#ffffff'}`,
  color: '#9D9DA6',
  border: `none`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  // backgroundColor: `${themeMode === 'dark' ? '#1e1e2d !important' : '#ffffff !important'}`,
  backgroundColor: '#1e1e2d !important',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  '& .MuiTypography-root': {
    fontWeight: '500',
    lineHeight: '21px',
    fontSize: '14px'
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: '#9D9DA6',
  },


}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: `${themeMode === 'dark' ? '#151521 !important' : '#f5f8fa !important'}`,
  // borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

type Props = {
  topic: string,
}

export const CustomizedAccordions: FC<Props & WithChildren> = ({ topic,children }) => {
  const [expanded, setExpanded] = React.useState<boolean>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(prev => !prev);
    };

  return (
    <div>
      <Accordion expanded={expanded} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>{topic}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {children}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}