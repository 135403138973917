import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import { MasterInit } from '../_metronic/layout/MasterInit'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  return (
    <>
      <ToastContainer
        className="text-white font-size-h6 word-wrap"
        position="top-center"
        style={{ width: "30em" }}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Suspense fallback={<LayoutSplashScreen />}>
        <LayoutProvider>
          <Outlet />
          <MasterInit />
        </LayoutProvider>
      </Suspense>
    </>
  )
}

export { App }
