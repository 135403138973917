/* eslint-disable react/jsx-no-target-blank */
import { useEffect } from 'react'
import { ILayout, useLayout } from '../../core'

const Footer = () => {
  const { config } = useLayout()
  useEffect(() => {
    updateDOM(config)
  }, [config])
  const today = new Date().getFullYear();

  return (
    <>
      <div className='text-dark order-2 order-md-1'>
        <span className="text-muted fw-semibold me-1">
          {today.toString()}
        </span>
        <span className="text-muted fw-semibold me-1">
          &copy;{" "}
        </span>
        <span className='text-muted fw-semibold me-1'>
          The Coca-Cola build #{process.env.REACT_APP_BUILD_NUM}
        </span>
      </div>
    </>
  )
}

const updateDOM = (config: ILayout) => {
  if (config.app?.footer?.fixed?.desktop) {
    document.body.classList.add('data-kt-app-footer-fixed', 'true')
  }

  if (config.app?.footer?.fixed?.mobile) {
    document.body.classList.add('data-kt-app-footer-fixed-mobile', 'true')
  }
}

export { Footer }
