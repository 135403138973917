import { CustomizedAccordions } from '../components/accordion/accordion';
import { useServiceRedux } from '../../app/modules/ServiceRuns/redux/useServiceRedux';
import { Link } from "react-router-dom";
import { FC } from 'react';
import { useAppSelector } from "../../app/redux/store";
import { useGlobalContext } from '../../app/components/GlobalContext/GlobalContext';
import { serviceConfig } from '../../app/modules/ServiceRuns/redux/cloudAppAPI';
import { menus } from '../../_metronic/layout/components/header/header-menus/MenuInner'

interface featuresData {
    topic: string
}

export const MenuItem:FC<featuresData> = ({topic}) => {
  const { bu } = useGlobalContext();
  const { data } = useAppSelector((state) => serviceConfig(state))
  const { serviceConfigNames } = useServiceRedux();
  

  return (
    <div className='d-flex flex-column menu-full-width'>
      <div className='service-menu'>
        <CustomizedAccordions topic={topic}>
          <div className='menu-accordion-content'>
            {data && menus.filter(menu => menu.title === topic)[0].services
              .filter(el => (serviceConfigNames?.includes(el.path) && data.filter(entry => entry.service_descriptive_name === el.path)[0].buConfig.map(entry => entry.value).includes(bu)))
              .map(feature => {
                return (
                   <Link to={`/services/createservicerun/${feature.path}`} key={feature.path} className='text-hover-primary menu-card-link'>
                      <div className='card text-center menu-card-container'>
                        <i className={feature.cardLogoClasses}></i>
                        <h3 className={`card-title fw-bold bg-homepage-card-text`}>
                          {feature.name}
                        </h3>
                      </div>
                  </Link>
                )
              }
              )}
          </div>
        </CustomizedAccordions>
      </div>
    </div>


  )
}