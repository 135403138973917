import React, { useState, useEffect } from "react";
import { ProgressBar } from "react-bootstrap";

export function ModalProgressBarAnimated({ variant = "info", label="" }) {

    // let [progress, setProgress] = useState(now);
  
    // useEffect(() => {
    //   const timer = setTimeout(() => {
    //     if (progress < 100) {
    //       setProgress(progress + 10)
    //     }
    //   }, 20);
    //   return () => {
    //     clearTimeout(timer);
    //   };
    // }, [progress, setProgress])
  
    return (
      <ProgressBar
        label={label}
        variant={variant}
        animated
        now={100}
        style={{ 
          height: "1rem", 
          width: "100%",
          fontSize: "0.9rem",
          color:'white',
          fontWeight:'800'
          // position: "absolute" 
        }}
      />
    );
  }