import { useEffect, useState, Fragment } from "react";
import { FC } from 'react';
import { useServiceRunsContext } from "../../../app/modules/ServiceRuns/context/ServiceRuns-context";
import { CircularProgress, DialogContent, Tooltip } from '@mui/material';
import { CustomMuiAccordion } from "./CustomMuiAccordion"
import { useLazyConnectDBQuery } from "../../../app/modules/ServiceRuns/redux/cloudAppAPI";
import { useCheckQueryReady } from "../../../app/modules/ServiceRuns/hooks/RoleTableHooks";
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';

export interface HistoryButtonInterface {
  form: {
    initialValues: { jsonID: { [key: string]: string } },
    errors: { jsonID: { [key: string]: string[] } },
    values: { jsonID: { [key: string]: (string | null) | Array<{ [key: string]: string | null }> | null | string[] }, serviceID: string }
  },
  id: string,
  label: string,
  queryparams: string,
  tableValues: { id: string, label: string, monitored: boolean }[],
  modalTopic: string
}

interface HistoryItem {
  operation: string, 
  koUserID: string, 
  timeStamp: string, 
  changedFields: { [key: string]: string}[]
}

export const HistoryButton: FC<HistoryButtonInterface> = ({
  label,
  id,
  form,
  queryparams,
  tableValues,
  modalTopic
}) => {
  
  const [trigger, { data, isLoading, isFetching }] = useLazyConnectDBQuery();
  const [show, setShow] = useState(false);
  const { editTable } = useServiceRunsContext();
  const [records, setRecords] = useState<HistoryItem[]>([])
  const matches = useMediaQuery('(min-width:600px)');
  const [expanded, setExpanded] = useState<string>("");
  const { queryValuesObject, queryReady } = useCheckQueryReady(form, queryparams);

  useEffect(() => {
    if (queryReady && editTable) {
      trigger({ queryParams: queryValuesObject, service: form.values.serviceID, fieldName: id, type: "action_buttons_config" })
    }

  }, [queryReady])

  useEffect(() => {
    if (data && "tabledata" in data) {
      setRecords(data.tabledata.map(row => 
        ({
          operation: row.isAdd === "Y" ? "Add" : "Update", 
          koUserID: row.isAdd === "Y" ? row.crt_ko_user_id : row.updt_ko_user_id, 
          timeStamp: row.isAdd === "Y" ? row.crt_dttm : row.updt_dttm, 
          changedFields: tableValues.map(val => 
            ({
              columnName: val.label,
              oldValue: val.monitored ?
                (row[`old_${val.id}`] === "None" ? "" : row[`old_${val.id}`]) :
                (row.isAdd === "Y" ? "" : row[val.id]),
              newValue: row[val.id]
            })
          )
        })
      ))
    }

  }, [data])


  return (
    <>
      {editTable &&
        <>
          <button
            type='button'
            className='btn btn-color-success btn-success text-white btn-sm me-5'
            data-kt-menu-trigger='click'
            onClick={() => setShow(true)}
            disabled={(isLoading || isFetching) ? true : false}
          >
            <div className="d-flex align-items-center">
              <span >{label}</span>
              {(isLoading || isFetching) && (
                <CircularProgress className="ms-2" size='21px' />
              )}
            </div>
          </button>
          <Dialog
            open={show}
            onClose={() => setShow(false)}
            fullScreen={!matches}
            maxWidth={'md'}
          >
            <DialogContent>
              {(isLoading || isFetching) ?
                <div className="d-flex justify-content-center align-items-center p-10">
                  <CircularProgress />
                  <h5 className="ms-5">Loading Data.....</h5>
                </div>
                :
                <div className="history_frame">
                  <div className='d-flex justify-content-between align-items-center pb-3'>
                    <div>
                      <h2>{modalTopic}</h2>
                    </div>
                    <div>
                      <Tooltip title="Close" placement="right-start">
                        <button
                          type='button'
                          className='btn btn-color-success btn-danger text-white btn-sm me-5'
                          data-kt-menu-trigger='click'
                          onClick={() => setShow(false)}
                        >
                          X
                        </button>
                      </Tooltip>
                    </div>
                  </div>
                  <div className="row">
                    <div className="d-flex fw-bolder history_content_header">
                      <div className="history_content_header_empty_space"></div>
                      <div className="history_content_header_items">
                        <div className="history_item">
                          Timestamp
                        </div>
                        <div className="history_item">
                          KO User ID
                        </div>
                        <div className="history_last_item">
                          Operation
                        </div>
                      </div>
                    </div>
                    {records.length > 0 && records.map(rec => (
                      <div
                        style={{ padding: 0 }}
                        key={rec.timeStamp}
                      >
                        <CustomMuiAccordion
                          accordionTopic={
                            <div className="d-flex flex-grow-1">
                              <div className="history_item">
                                {rec.timeStamp}
                              </div>
                              <div className="history_item">
                                {rec.koUserID}
                              </div>
                              <div className="history_last_item">
                                {rec.operation}
                              </div>
                            </div>
                          }
                          prKey={rec.timeStamp}
                          setExpanded={setExpanded}
                          expanded={expanded === rec.timeStamp}
                        >
                          <div className={`${matches ? "px-15" : ""}`}>
                            <div className="d-flex flex-grow-1 history_table_header">
                              <div className="history_table_first_item">
                                Column Name
                              </div>
                              <div className="history_table_second_item">
                                Old Value
                              </div>
                              <div className="history_table_last_item">
                                New Value
                              </div>
                            </div>
                            {
                              rec.changedFields.map(f => (
                                <Fragment key={f.columnName}>
                                  <div className="d-flex flex-grow-1" >
                                    <div className="d-flex flex-grow-1 history_table_content">
                                      <div className="history_table_first_item">
                                        {f.columnName}
                                      </div>
                                      <div className="history_table_second_item">
                                        {f.oldValue}
                                      </div>
                                      <div className="history_table_last_item">
                                        {f.newValue}
                                      </div>
                                    </div>
                                  </div>
                                </Fragment>
                              ))
                            }
                          </div>
                        </CustomMuiAccordion>
                      </div>
                    ))
                    }
                  </div>
                </div>
              }
            </DialogContent>
          </Dialog>
        </>
      }
    </>
  );
}