import React, { Component, ErrorInfo, ReactNode } from "react";
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import SVG from "react-inlinesvg";
import { appInsights } from '../../app_insight/app_insight_config'
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { connect } from "react-redux";
import { RootState } from "../../redux/store"
import { getUserName } from '../auth/redux/authSlice'

interface Props {
  children?: ReactNode;
  state: {}
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const userName = getUserName(this.props.state as RootState)

    appInsights.trackException({ error: error, severityLevel: SeverityLevel.Error, properties: { 'source': 'nsr_portal','user': userName} });
  }

  public render() {
    if (this.state.hasError) {

      return (
        <>
          <div>
            <div className="d-flex justify-content-center pt-5 mt-15">
              <h1>Sorry! There was an error on the page!</h1>
            </div>
            <div className="d-flex justify-content-center pb-5 mb-5">
              <h1>The Support team has been notified!</h1>
            </div>
            <div className='d-flex justify-content-center pb-5 mb-15'>
              <a href='/' className='btn btn-sm btn-primary'>
                Return Home
              </a>
            </div>
            <div className="d-flex justify-content-center">
              <SVG src={toAbsoluteUrl("/media/auth/page-not-found.svg")} />
            </div>

          </div>
        </>

      );
    }

    return this.props.children;
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    state: state
  }
}

export default connect(mapStateToProps)(ErrorBoundary)