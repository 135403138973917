import { useEffect, useState } from "react";
import { serviceConfig } from '../modules/ServiceRuns/redux/cloudAppAPI'
import { useAppSelector } from '../redux/store';
import { useGlobalContext } from '../components/GlobalContext/GlobalContext'
import { serviceConfigInterface } from '../modules/ServiceRuns/interfaces/serviceRunsInterfaces'

type buObjectArrayType = { name: string; value: string; rang: number }[]

const estimeBUOptions: (data: serviceConfigInterface[]) => buObjectArrayType = (data) => {
  let buRang: { name: string; value: string; rang: number }[] = [];
  data.forEach(conf => {
    conf.buConfig.forEach(bu => {
      const buInArray = buRang.filter(item => item.value === bu.value)
      if (buInArray.length > 0) {
        buInArray[0].rang = buInArray[0].rang + 1;
      } else {
        buRang.push({ name: bu.name, value: bu.value, rang: 1 })
      }
    })
  })
  return buRang
}

export const useGetBUOptions = () => {
  const [userBUOptions, setUserBUOptions] = useState([{ name: "Loading...", value: "default" }]);
  const { data } = useAppSelector((state) => serviceConfig(state));

  useEffect(() => {
    if (data) {
      const buOptions = estimeBUOptions(data).map(bu => ({ name: bu.name, value: bu.value }))
      // const buData = estimeBUOptions(data)
      // let buOptions: { name: string; value: string }[] = [];
      // buData.forEach(opt => buOptions.push({ name: opt.name, value: opt.value }))
      setUserBUOptions(buOptions)
    }

  }, [data]);

  return userBUOptions;
};

export const useGetBUfromLS = () => {
  const { data } = useAppSelector((state) => serviceConfig(state));
  const { bu, setBU } = useGlobalContext();

  useEffect(() => {
    if (data) {
      let buObjectArray: buObjectArrayType = estimeBUOptions(data);
      let biggestAfterFirst = 0;
      let firstBUOption: { name: string, value: string };
      
      if (buObjectArray.length > 0) {
        buObjectArray.forEach(item => {
          if (item.rang > biggestAfterFirst) {
            biggestAfterFirst = item.rang;
            firstBUOption = { name: item.name, value: item.value }
          }
        })
        
        const bus = buObjectArray.map(op => op.value)
        if (bu === "default" && firstBUOption!) {
          if (localStorage.getItem("bu") && bus.includes(localStorage.getItem("bu") as string)) {
            setBU(localStorage.getItem("bu") as string)
          } else {
            setBU(firstBUOption.value)
          }
        } else {
          if (bu !== localStorage.getItem("bu")) {
            localStorage.setItem('bu', bu)
          }
        }
      }
    }
  }, [data, bu]);
};
