import clsx from 'clsx'
import { KTSVG } from '../../../helpers'
import { HeaderUserMenu } from '../../../partials'
import { useLayout } from '../../core'
import { useAuthRedux } from "../../../../app/modules/auth"
import { ThemeModeSwitcher } from "../../../../_metronic/partials/layout/theme-mode/ThemeModeSwitcher"
import { useGetBUOptions, useGetBUfromLS } from '../../../../app/hooks/useGetBU'
import { useGlobalContext } from '../../../../app/components/GlobalContext/GlobalContext'

const itemClass = 'ms-1 ms-lg-3'
// const btnClass =
//   'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'svg-icon-1'

const Navbar = () => {
  const { config } = useLayout()
  const { fullName, imageURL } = useAuthRedux();
  const { bu, setBU } = useGlobalContext();
  const buOptions = useGetBUOptions();
  useGetBUfromLS();

  const handleChange = (event: { target: { value: any } }) => {
    setBU(event.target.value);
    // You can also do any additional actions with the selected option here
  }
  const disabled = bu === "default"

  return (
    <>
      <div className='app-navbar flex-shrink-0'>
        <div className='ms-6 py-5 d-flex'>
          <select value={bu} onChange={handleChange} disabled={disabled} style={{ backgroundColor: '#9D9DA6', borderRadius: '6.175px' }}>
            {buOptions && buOptions.map(lbu => (<option key={lbu.value} value={lbu.value}>{lbu.name}</option>))}
          </select>
        </div>
        <div className={clsx('app-navbar-item', itemClass)}>
          <ThemeModeSwitcher toggleBtnClass={clsx('btn-active btn-custom')} />
        </div>
        <div className={clsx('app-navbar-item', itemClass)}>
          <div
            className={clsx('cursor-pointer symbol', userAvatarClass)}
            data-kt-menu-trigger="{default: 'click'}"
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
          >
            {imageURL ? <img alt='User profile' src={imageURL} /> :
              <span className="symbol-label font-size-h5 fw-bold">{fullName ? `${fullName[0]}${fullName.split(" ")[1][0]}` : null}</span>}
          </div>
          <HeaderUserMenu />
        </div>

        {config.app?.header?.default?.menu?.display && (
          <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
            <div
              className='btn btn-icon btn-active-color-primary w-35px h-35px'
              id='kt_app_header_menu_toggle'
            >
              <KTSVG path='/media/icons/duotune/text/txt001.svg' className={btnIconClass} />
            </div>
          </div>
        )}
      </div>
    </>

  )
}

export { Navbar }
