import { shallowEqual, useSelector } from "react-redux";
import { RootState } from '../../../redux/store'
import { useAppSelector } from "../../../redux/store";
import { authProps } from './authSlice'
import { useDispatch } from 'react-redux'
import { hideExpiringModal } from './authSlice'

export const useAuthRedux = () => {
  const {
    showExpiringModal
  } = useSelector(
    (state: RootState) => ({
      showExpiringModal: state.auth.showExpiringModal,
    }),
    shallowEqual
  );
  const { data } = useAppSelector((state) => authProps(state))
  const dispatch = useDispatch();
  const hideExpiringModalAction = () => {
    dispatch(hideExpiringModal({}))
  }

  return {
    userName: data?.userName,
    fullName: data?.fullName,
    imageURL: data?.imageURL,
    tokenExpiration: data?.tokenExpiration,
    showExpiringModal,
    accessToken: data?.accessToken,
    graphToken: data?.graphAccessToken,
    hideExpiringModalAction
  }
}