import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString: `InstrumentationKey=${process.env.REACT_APP_AZURE_INSTURMENTATION_KEY};IngestionEndpoint=${process.env.REACT_APP_AZURE_INGESTION_ENDPOINT};LiveEndpoint=${process.env.REACT_APP_AZURE_LIVE_ENDPOINT}`,
    extensions: [reactPlugin],
    extensionConfig: {},
    /* not needed, sets timer on page visit and reports timings and etc. */
    autoTrackPageVisitTime: false,
    /* false by default, disables logging react exceptions in AI */
    disableExceptionTracking: true,
    /* also it enables request path in telemetry tags (as operation_Name in AI) when PageviewData is false in telemetryConfig */
    enableAutoRouteTracking: false,
    /* for enabled tracking of Ajax requests to FA */
    disableAjaxTracking: true,
    /* for enabled tracking of Fetch requests to SA/COS */
    disableFetchTracking: true,
  }
});
appInsights.loadAppInsights();

export { reactPlugin, appInsights };