import { msalInstance } from "../../../../index";
import { scopeInterface } from "../core/AuthConfig"
import { PublicClientApplication, EventType, EventMessage, AuthenticationResult } from "@azure/msal-browser";
import { msalConfig } from "../core/AuthConfig"


export const GRAPH_ENDPOINTS = {
  MEGROUPS: "https://graph.microsoft.com/v1.0/me/checkMemberObjects",
  PHOTO: "https://graph.microsoft.com/v1.0/me/photo/$value"
};

export interface fetchBody {
  ids: string[]
}

export const fetchMsGraph = async (url: string, method: string, accessToken: string, body: fetchBody | null) => {
  let response;
  if (body) {
    response = await fetch(url, {
      method: method,
      body: JSON.stringify(body),
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': "application/json"
      }
    });
  } else {
    response = await fetch(url, {
      method: method,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': "application/json"
      }
    });
  }

  return response.json();
};

export const fetchMsGraphPhoto = async (url: string, accessToken: string) => {
  let response;

  response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': "image/jpg"
    }
  });

  return response;
};

export const getAccessToken = async (tokenType: scopeInterface) => {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
  }

  tokenType.account = account
  const response = await msalInstance.acquireTokenSilent(tokenType);

  return response.accessToken;
}

export const getUserImage = async (accessToken: string) => {
  const responseImageObject = await fetchMsGraphPhoto(
    GRAPH_ENDPOINTS.PHOTO,
    accessToken
  );
  let base64Image;
  const blobToBase64 = (blob: Blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };
  if (responseImageObject.status === 200) {
    let imageBlob = await responseImageObject.blob()
    base64Image = await blobToBase64(imageBlob)
  }
  return base64Image as string | undefined;
}

export const checkAuthExpiration = (tokenExpiration: number | undefined) => {
  let firstDelay: number;
  let secondDelay: number;
  // more than 13 minutes

  if (tokenExpiration! > 780000) {
    // 10 minutes before expiration
    firstDelay = tokenExpiration! - 600000;
    // log off after 8 minutes
    secondDelay = 480000;
    // between 7 and 13 minutes
  } else if (tokenExpiration! <= 780000 && tokenExpiration! >= 420000) {
    // Show POPUP
    firstDelay = 0;
    // log off after 3 minutes
    secondDelay = 180000;
    // between 4 and 7 minutes
  } else if (tokenExpiration! < 420000 && tokenExpiration! > 240000) {
    firstDelay = 0;
    // DO NOT WAIT BECAUSE USER CAN BE AWAY, SO JUST LOG OFF HIM!
    secondDelay = 0;
  } else {
    firstDelay = 0;
    secondDelay = 0;
  }
  return {
    firstDelay,
    secondDelay
  }
}

export const MSALSetup = () => {
  const msalInstance = new PublicClientApplication(msalConfig);
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);
  }

  msalInstance.addEventCallback((event: EventMessage) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
          const payload = event.payload as AuthenticationResult;
          const account = payload.account;
          msalInstance.setActiveAccount(account);
      }
  });
  return msalInstance
}