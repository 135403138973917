import { useEffect, useState } from "react";

export const useRunRedux = (Hook: (e: { args: string }, params: { skip: boolean }) => { data: [{}], isFetching: boolean }, inputChars: string) => {
  const [run, setRun] = useState(false)
  const [oldInputChars, setOldInputChars] = useState(inputChars)
  const { data, isFetching } = Hook({ args: inputChars }, { skip: !run || inputChars.length < 3 })

  useEffect(() => {
    if (oldInputChars !== inputChars) {
      if (inputChars.length > 3) {
        setRun(true);
      } else {
        setRun(false);
      }
      setOldInputChars(inputChars)
    }

  }, [inputChars])
  return {
    records: inputChars.length > 3 ? data : [],
    loadingData: isFetching
  }
}