import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../modules/auth/redux/authSlice'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { cloudAppAPI } from '../modules/ServiceRuns/redux/cloudAppAPI'
import { setupListeners } from '@reduxjs/toolkit/query'


const store = configureStore({
  reducer: {
    auth: authReducer,
    [cloudAppAPI.reducerPath]: cloudAppAPI.reducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(cloudAppAPI.middleware)
})

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>();
export default store